import ApiService from "../api.service"

export default class NotificacoesService
{
    static async List()
    {
        return await ApiService.chamaApiV2(`v2/api/notificacao/ativas`)
    }
    

    static async Remove(rid)
    {
        return await ApiService.chamaApiV2(`v2/api/notificacao/Remover/${rid}`)
    }
}