import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import FlashMessage from '@smartweb/vue-flash-message'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.config.productionTip = false

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import Multiselect from 'vue-multiselect'
// import 'vue-multiselect/dist/vue-multiselect.css'

// register globally
// Vue.component('vue-multiselect', Multiselect)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSave, faPlus, faMinus,faExchangeAlt, faArrowRight,faArrowLeft,faUserTie, faFileExcel, faChevronUp, faChevronDown, faBan, faSearch, faHome, faBars, faSignOutAlt, faUserCircle, faBell, faCog, faChartBar, faTachometerAlt, faChevronRight, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons'

library.add(faSave,faExchangeAlt,faMinus,faArrowRight,faArrowLeft,faPlus,faUserTie, faFileExcel, faChevronUp, faChevronDown, faBan, faSearch, faHome, faBars, faSignOutAlt, faUserCircle, faBell, faCog, faChartBar, faTachometerAlt, faChevronRight, faAngleDoubleRight, faAngleRight)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(FlashMessage)

import FileReader from 'vue-filereader'
Vue.component(FileReader.name, FileReader)

import VueSingleSelect from "vue-single-select"
Vue.component('vue-single-select', VueSingleSelect)

import money from 'v-money'
// register directive v-money and component <money>
Vue.use(money, { decimal: ',',
thousands: '.',
prefix: '',
suffix: '',
precision: 2,
masked: false})

// import VCreditCard from 'v-credit-card'
// Vue.component('v-credit-card', VCreditCard)

// import 'v-credit-card/dist/VCreditCard.css'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'

Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')