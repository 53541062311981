import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const permissions = ['Avancado']

  if (to.matched.some(record => record.meta.requiresAuth)) {

    if (store.getters['auth/isLoggedIn'] && permissions.indexOf(store.getters['auth/userTipo']) != -1)
      return next()
    else
      return next('/login')
  } else
    return next()

    //return next()
})

export default router