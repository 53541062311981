import axios from 'axios'
import VueCookies from 'vue-cookies'
import DefaultUtils from '@/utils/default.utils'

export default class ApiService {

    static mapUrl(url, port = 5000) {
        if(!url)
            return

        if (!url.startsWith('/')) {
            url = `/${url}` 
        }

        let prodUrl = `${window.location.protocol}//${window.location.host}`
        let devUrl = `http://${window.location.hostname}:${port}`

        // fetch('https://ipapi.co/json/')
        // .then(function(response) {
        //     return response.json()
        // })
        // .then(function(data) {
        //     console.log(data)
        // })

        let apiUrl = ''

        if (process.env.NODE_ENV == "production") {
            apiUrl = prodUrl
        } else {
            apiUrl = devUrl
        }
        url = apiUrl + url

        return url
    }

    static mapUrlV2(url, port = 5001) {
        if(!url)
            return

        if (!url.startsWith('/')) {
            url = `/${url}` 
        }

        let prodUrl = `https://rentainvestsistema.com.br`
        let devUrl = `http://${window.location.hostname}:${port}`

        let apiUrl = ''

        if (process.env.NODE_ENV == "production") {
            apiUrl = prodUrl
        } else {
            apiUrl = devUrl
        }
        url = apiUrl + url

        return url
    }

    static getAccessToken() {
        return VueCookies.get("access_token")
    }

    static async chamaApi(api, dataObject = null) {
        let access_token = this.getAccessToken()

        //Content-Length

        let requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            },
        }

        if (access_token) {
            requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`,
                    'Cache-Control': 'no-cache'
                },
            }
        }

        


        if (dataObject) { // post 

            try {
                requestOptions.method = 'POST'
                requestOptions.body = dataObject

                const response = await axios.post(this.mapUrl(api), dataObject, requestOptions)

                return response.data

            } catch (ex) {
                let message = ""

                if (ex.response) {
                    message = ex.response
                    if(ex.response.data){
                        message = ex.response.data.Mensagem
                    }
                } else if (ex.request) {
                    message = ex.request
                } else {
                    message = ex.message
                }
                //console.log(ex)
                return {
                    Sucesso: false,
                    Mensagem: message,
                    Descricao : ex
                }
            }

        } else {// get
            try {

                requestOptions.method = 'GET'

                const response_1 = await axios.get(this.mapUrl(api), requestOptions)

                return response_1.data

            } catch (ex) {

                if(this.isObject(ex.data)){
                    return ex.data
                }

                let message = ""

                if (ex.response) {
                    message = ex.response
                    if(ex.response.data){
                        message = ex.response.data.Mensagem
                    }
                } else if (ex.request) {
                    message = ex.request
                } else {
                    message = ex.message
                }
                //console.log(ex)
                return {
                    Sucesso: false,
                    Mensagem: message,
                    Descricao : ex
                }
            }
        }
    }

    static async chamaApiV2(api, dataObject = null) {
        let access_token = this.getAccessToken()

        //Content-Length

        let requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            },
        }

        if (access_token) {
            requestOptions = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`,
                    'Cache-Control': 'no-cache'
                },
            }
        }

        


        if (dataObject) { // post 

            try {
                requestOptions.method = 'POST'
                requestOptions.body = dataObject

                const response = await axios.post(this.mapUrlV2(api), dataObject, requestOptions)

                return response.data

            } catch (ex) {
                let message = ""

                if (ex.response) {
                    message = ex.response
                    if(ex.response.data){
                        message = ex.response.data.Mensagem
                    }
                } else if (ex.request) {
                    message = ex.request
                } else {
                    message = ex.message
                }
                //console.log(ex)
                return {
                    Sucesso: false,
                    Mensagem: message,
                    Descricao : ex
                }
            }

        } else {// get
            try {

                requestOptions.method = 'GET'

                const response_1 = await axios.get(this.mapUrlV2(api), requestOptions)

                return response_1.data

            } catch (ex) {

                if(this.isObject(ex.data)){
                    return ex.data
                }

                let message = ""

                if (ex.response) {
                    message = ex.response
                    if(ex.response.data){
                        message = ex.response.data.Mensagem
                    }
                } else if (ex.request) {
                    message = ex.request
                } else {
                    message = ex.message
                }
                //console.log(ex)
                return {
                    Sucesso: false,
                    Mensagem: message,
                    Descricao : ex
                }
            }
        }
    }

    static isObject(variable) {
        return typeof variable === 'object' && variable !== null;
    }

    static interceptorsResponse() {
        return new Promise((resolve, reject) => {
            axios.interceptors.response.use((response) => {
                resolve(response)
            }, (err) => {
                reject(err)
            })
        })
    }

    static async chamaApi2(api, dataObject = null) {
        let requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                // 'Cache-Control' : 'no-cache'
            },
        }

        if (dataObject != null) { // post 

            try {
                requestOptions.method = 'POST'
                requestOptions.body = dataObject

                const response = await axios.post(api, dataObject, requestOptions)

                return response.data

            } catch (ex) {
                let ex_descrition = {}

                if (ex.response) {
                    ex_descrition = ex.response
                } else if (ex.request) {
                    ex_descrition = ex.request
                } else {
                    ex_descrition = ex.message
                }

                return {
                    Sucesso: false,
                    Mensagem: 'Erro no servidor',
                    Descricao: ex_descrition
                }
            }

        } else {// get
            try {

                requestOptions.method = 'GET'

                const response_1 = await axios.get(api, requestOptions)

                return response_1.data

            } catch (ex) {
                let ex_descrition = {}

                if (ex.response) {
                    ex_descrition = ex.response
                } else if (ex.request) {
                    ex_descrition = ex.request
                } else {
                    ex_descrition = ex.message
                }

                return {
                    Sucesso: false,
                    Mensagem: 'Erro no servidor',
                    Descricao: ex_descrition
                }
            }
        }
    }

    static async getCep(cep){
        try{
            cep = DefaultUtils.somenteNumeber(cep)

            const url = `https://viacep.com.br/ws/${cep}/json/`

            const response = await axios.get(url)

            return response.data

        }catch(ex){
            let ex_descrition = {}

                if (ex.response) {
                    ex_descrition = ex.response
                } else if (ex.request) {
                    ex_descrition = ex.request
                } else {
                    ex_descrition = ex.message
                }

                return {
                    Sucesso: false,
                    Mensagem: 'Erro no servidor',
                    Descricao: ex_descrition
                }
        }
    }
}
