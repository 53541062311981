import DefaultUtils from '@/utils/default.utils'
import NotificacoesService from '@/services/bussines/notificacoes.service'
import VueCookies from "vue-cookies"

const _data_source = DefaultUtils.jsonPaseStr(DefaultUtils.base64(DefaultUtils.getCookie('notification')))

const state = {
  data_source: typeof _data_source === 'boolean' ? [] : _data_source,
  notificationQtd: 0,
}

// getters
const getters = {
  notificationQtd: state => state.notificationQtd,
  notificacoesLst: state => state.data_source
}

// actions
const actions = {

  async geNotification({ commit }) {
   
    const response = await NotificacoesService.List()

    commit("setNotification", response)
  },

  async removeItemNotif({ commit }, rid) {
    
    const response = await NotificacoesService.Remove(rid)

    commit('removeItem', rid)
  },
}

// mutations
const mutations = {

  setNotification(state, resp) {
    // console.log(state)
    // console.log(resp)
    state.data_source = resp
    state.notificationQtd = state.data_source.length

  
    if (state.notificationQtd > 0) {
      VueCookies.set("notification", DefaultUtils.base64(JSON.stringify(state.data_source)))
    } else {
      DefaultUtils.removeCookie("notification")
    }
  },
   removeItem(state, rid) {

    state.data_source = state.data_source.filter(a => {
      return a.RID != rid
    })
    state.notificationQtd = state.data_source.length

    if (state.data_source.length > 0) {
      DefaultUtils.setCookie('notification', DefaultUtils.base64(JSON.stringify(state.data_source)))
    } else {
      DefaultUtils.removeCookie('notification')
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}