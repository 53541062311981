import VueCookies from 'vue-cookies'


export default class DefaultUtils {
    static validPassword(senha) {
        if (this.validaVariavel(senha) && senha.length >= 6) {
            if (
                /[0-9]/gm.test(senha) &&
                /[a-z]/gm.test(senha) &&
                /[A-Z]/gm.test(senha) &&
                /[!@#$%*()_+^&{}}:?.]/gm.test(senha)
            )
                return true
        }
        return false
    }

    static groupBy (array, key) {
        return array.reduce((acc, item) => {
            if (!acc[item[key]]) acc[item[key]] = []
            acc[item[key]].push(item)
            return acc
        }, {})
    }
    
    static atualizarRegistros(array, criterio, novosValores) {
        for (let i = 0; i < array.length; i++) {
          if (criterio(array[i])) {
            array[i] = Object.assign({}, array[i], novosValores)
          }
        }
        return array
     }

    static limitCaracter(value, limit) {
        try {
            return `${value.substr(0, limit)}...`
        } catch (ex) {
            return ""
        }
    }

    static jsonPaseStr(str) {
        try {
            return JSON.parse(str)
        } catch (ex) {
            return false
        }
    }

    static validEmail(email) {
        try {
            let re = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(email)
        } catch (ex) {
            return false
        }
    }
    static validaVariavel(value) {
        if (typeof value != "undefined" && value)
            return true
        else
            return false
    }

    static isNumeric(value) {
        if (!isNaN(Number(value)))
            return true
        else
            return false
    }

    static setCookie(key, item) {
        VueCookies.set(key, item)
    }

    static removeCookie(key) {
        VueCookies.remove(key)
    }

    static getCookie(key) {
        try {
            return VueCookies.get(key)
        }
        catch (ex) {
            return []
        }
    }

    static somenteNumeber(value) {
        try {
            if (value)
                return value.toString().replace(/[\D]+/g, '')

            return ""
        } catch (ex) {
            return ""
        }
    }

    static ReplaceNumeber(value) {
        try {
            return value.toString().replace(/[^0-9,.]/g, "")
        } catch (ex) {
            return ""
        }
    }

    static isInteger(value) {
        try {
            return value % 1 === 0
        } catch (ex) {
            return false
        }
    }

    // static formatReal(value)
    // {
    //     try{
    //         if(!value)
    //             return "0,00"

    //         let tmp = null
    //         if(this.isInteger(value)){
    //             tmp = Number(value)
    //             tmp = parseFloat(value).toFixed(2).replace('.',',')
    //         }else{
    //             tmp = Number(value.toString().replace(/[\D]+/g,''))
    //             tmp = parseFloat(tmp/100).toFixed(2).replace('.',',')
    //         }

    //         return tmp
    //     }catch(ex){
    //         return "0,00"
    //     }
    // }

    static formatReal(valor) {
        if (!valor)
            return ""

        const toNumberStr = (n) => {
            return new Intl.NumberFormat('pt-BR', {
                //   style: 'currency',
                //   currency: 'BLR',
            }).format(n)
        }
        return toNumberStr(valor)
    }

    static toFloat(value) {
        try {
            if (typeof value == 'number') {
                return parseFloat(value).toFixed(2).replace('.', ',')
            } else {
                return Number(parseFloat(value.replace(',', '.')).toFixed(2))
            }
        } catch (ex) {
            return null
        }
    }



    static formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
        if (isNaN(Number(amount)))
            return amount

        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
            let j = (i.length > 3) ? i.length % 3 : 0;

            return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
        } catch (e) {
            console.error(e)
        }
    }

    static base64(value) {
        try {
            if (!this.validaVariavel(value))
                return ''

            return this.isBase64(value) ? atob(value) : btoa(value)
        } catch (ex) {
            return ''
        }
    }

    static isBase64(str) {
        try {
            return btoa(atob(str)) == str
        } catch (err) {
            return false
        }
    }


    static getUserData() {
        try {
            return this.base64(VueCookies.get("user_session"))
        } catch (ex) {
            return null
        }
    }


    static isObjectEmpty(obj) {
        return Object.keys(obj).length === 0
    }

    static isKeyExists(obj, key) {
        return key in obj
    }

    static cepFMP(cep) // XXXXX-XXX
    {
        if (cep) {
            cep = cep.replace(/\D/g, "")
            cep = cep.replace(/^(\d{5})(\d)/, "$1-$2")
            return cep
        }
    }

    static celularFMT(tel) // (XX) XXXX.XXXX
    {
        if (!tel)
        return ""

        tel = tel.replace(/\D/g, "")

        // Formatar número brasileiro
        if (tel.length > 11) {
            tel = tel.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "+$1 ($2) $3-$4")
        } else {
            tel = tel.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, "+$1 ($2) $3-$4")
        }

        return tel
    }

    static clearObject(value) {
        try {
            for (let key in value) {

                if (!this.isObjectEmpty(value[key])) {
                    value[key] = this.clearObject(value[key])
                } else if (Array.isArray(value[key])) {
                    value[key] = []
                } else if (typeof value[key] == "number") {
                    value[key] = 0
                } else {
                    value[key] = null
                }
            }
            return value
        } catch (ex) {
            return {}
        }
    }

    static paginate(totalItems, currentPage = 1, pageSize = 50, maxPages = 5) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize)

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1
        } else if (currentPage > totalPages) {
            currentPage = totalPages
        }

        let startPage, endPage

        if (totalPages <= maxPages) {
            // total pages less than max so show all pages
            startPage = 1
            endPage = totalPages
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2)
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1

            if (currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1
                endPage = maxPages
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1
                endPage = totalPages
            } else {
                // current page somewhere in the middle
                startPage = currentPage - maxPagesBeforeCurrentPage
                endPage = currentPage + maxPagesAfterCurrentPage
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1)

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i)

        let _rangeFMT = [startIndex, endIndex + 1]

        // if(currentPage == 1){
        _rangeFMT = [startIndex + 1, endIndex + 1]
        // }

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            rangeFMT: _rangeFMT,
            pages: pages
        }
    }

    static serializeQuery(params, clear_null = false) {
        if (!params)
            return null
        
        params = clear_null ? this.ClearObject(params) : params
        return `?${Object.keys(params).map(key => key + '=' + params[key]).join('&')}`
    }

    static getState(value) {
        const estados = [
            { uf: 'AC', name: 'Acre' },
            { uf: 'AL', name: 'Alagoas' },
            { uf: 'AP', name: 'Amapá' },
            { uf: 'AM', name: 'Amazonas' },
            { uf: 'BA', name: 'Bahia' },
            { uf: 'CE', name: 'Ceará' },
            { uf: 'DF', name: 'Distrito Federal' },
            { uf: 'ES', name: 'Espírito Santo' },
            { uf: 'GO', name: 'Goías' },
            { uf: 'MA', name: 'Maranhão' },
            { uf: 'MT', name: 'Mato Grosso' },
            { uf: 'MS', name: 'Mato Grosso do Sul' },
            { uf: 'MG', name: 'Minas Gerais' },
            { uf: 'PA', name: 'Pará' },
            { uf: 'PB', name: 'Paraíba' },
            { uf: 'PR', name: 'Paraná' },
            { uf: 'PE', name: 'Pernambuco' },
            { uf: 'PI', name: 'Piauí' },
            { uf: 'RJ', name: 'Rio de Janeiro' },
            { uf: 'RN', name: 'Rio Grande do Norte' },
            { uf: 'RS', name: 'Rio Grande do Sul' },
            { uf: 'RO', name: 'Rondônia' },
            { uf: 'RR', name: 'Roraíma' },
            { uf: 'SC', name: 'Santa Catarina' },
            { uf: 'SP', name: 'São Paulo' },
            { uf: 'SE', name: 'Sergipe' },
            { uf: 'TO', name: 'Tocantins' },
        ]
        const find = estados.find(a => a.name.toLowerCase() === value.toLowerCase())

        if (find) {
            return find.uf
        }
        return null
    }

    static getDateBr(value) {
        if (value) {
            const data = value.split('-')
            return `${data[2]}/${data[1]}/${data[0]}`
        }
        return ""
    }

    //format = 'dd/MM/yyyy'
    static formatDateIsoToBr(value, format = '') {
        if (!value)
            return ""

        const date = new Date(value)

        let dd = date.getDate()
        let MM = date.getMonth() + 1
        let yyyy = date.getFullYear()
        let H = date.getHours()
        let mm = date.getMinutes()
        let ss = date.getSeconds()

        if (dd < 10)
            dd = `0${dd}`

        if (MM < 10)
            MM = `0${MM}`

        if (H < 10)
            H = `0${H}`

        if (mm < 10)
            mm = `0${mm}`

        if (ss < 10)
            ss = `0${ss}`

        switch (format) {
            case 'dd/MM/yyyy':
                return `${dd}/${MM}/${yyyy}`
            case 'dd/MM/yyyy H:mm:ss':
                return `${dd}/${MM}/${yyyy} ${H}:${mm}:${ss}`
            case 'dd/MM/yyyy H:mm':
                return `${dd}/${MM}/${yyyy} ${H}:${mm}`
            default:
                return `${yyyy}-${MM}-${dd}`
        }
    }

    static formatDateInput(value) {
        if (!value)
            return

        value = value.split('-')

        const yyyy = value[0]
        const MM = value[1]
        const dd = value[2]

        return `${dd}/${MM}/${yyyy}`
    }

    static LetrasNumeros(str) {
        if (str)
            return str.replace(/[^a-zA-Z 0-9 -]/g, '')
        return ""
    }

    static StringToBoolean(vlr) {
        return vlr === "false" ? false : true
    }

    static setUserData(value, expires = "60MIN") {
        VueCookies.set("user_session", this.base64(JSON.stringify(value)), expires)
    }

    static existItemsArray(dataLst, findLst) {
        let existe = false

        for (let item of findLst) {
            if (dataLst.indexOf(item) != -1) {
                existe = true
                break
            }
        }
        return existe
    }

    static shuffleString(str, maxlength) {
        let shuffledString = str.split('').sort(() => { return 0.5 - Math.random() }).join('')
        if (maxlength > 0) {
            shuffledString = shuffledString.substr(0, maxlength)
        }
        return shuffledString
    }

    static generatePassword(length, rules) {
        if (!length || length == undefined) {
            // default length
            length = 8
        }

        if (!rules || rules == undefined) {
            // default rules
            rules = [
                { chars: "abcdefghijklmnopqrstuvwxyz", min: 3 },
                { chars: "ABCDEFGHIJKLMNOPQRSTUVWXYZ", min: 2 },
                { chars: "0123456789", min: 2 },
                { chars: "!@#$&*?|%+-_./:;=()[]{}", min: 1 }
            ]
        }

        let allChars = "", allMin = 0

        rules.forEach((rule) => {
            allChars += rule.chars
            allMin += rule.min
        })
        if (length < allMin) {
            length = allMin
        }
        rules.push({ chars: allChars, min: length - allMin })

        let pswd = ""
        rules.forEach((rule) => {
            if (rule.min > 0) {
                pswd += this.shuffleString(rule.chars, rule.min)
            }
        });

        return this.shuffleString(pswd)
    }

    static padTo2Digits(num) {
        return num.toString().padStart(2, '0')
    }

    static formatDate(date) {
        return [
            this.padTo2Digits(date.getDate()),
            this.padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/')
    }

    static ClearObject(obj) {
        Object.keys(obj).forEach(key => {
            if (!obj[key])
                delete obj[key]
        })
        return obj
    }

    static isEmpty(obj) {
        if (!obj)
            return true

        return Object.keys(obj).length === 0
    }

    static isJson(str) {
        try {
            JSON.parse(str)
        } catch (e) {
            return false
        }
        return true
    }

    static GetMesAnoAtual(){
        const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
        "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
        let hoje = new Date()
        //console.log(monthNames[hoje.getMonth()],)
        return {
            month: monthNames[hoje.getMonth()],
            month_number: hoje.getMonth() + 1,
            year: hoje.getFullYear()
        }
    }

    static GetMesNumberByName(mes_name){
        const monthNames = [
            "Janeiro", 
            "Fevereiro", 
            "Março", 
            "Abril", 
            "Maio", 
            "Junho",
            "Julho", 
            "Agosto", 
            "Setembro", 
            "Outubro", 
            "Novembro", 
            "Dezembro"
        ]
        let mes_number = monthNames.indexOf(mes_name)

        if(mes_number == -1)
            return null
            
        return mes_number + 1
    }
}