// Função de validação de parâmetros de query
function validateQueryParams(query) {
    const { pedido_rid, invoice_rid } = query
    // Exemplo de validação: verificar se os parâmetros estão presentes e são strings não vazias
    if (typeof pedido_rid === 'string' && pedido_rid !== '' && 
        typeof invoice_rid === 'string' && invoice_rid !== '') {
      return true
    }
    return false
}

export default [
    {
        path: '/login',
        name: 'gestao-login',
        component: () => import('../views/Login.vue'),
        meta: { 
            requiresAuth: false,
            appearFor : []
        },
    },
    {
        path: '/site',
        name: 'Site',
        component: () => import('../views/Site/SiteLayout.vue'),
        meta: { 
            requiresAuth: false,
        },
        children : [
            {
                path: '/site/pedido-payment/:rid',
                name: 'Pagamento pedido',
                component: () => import('../components/Site/PedidoPagamento/index.vue'),
                meta: { 
                    requiresAuth: false,
                },
            },
            {
                path: '/site/pedido-payment/:rid/:conta_receber',
                name: 'Pagamento pedido conta receber',
                component: () => import('../components/Site/PedidoPagamentoFatura/index.vue'),
                meta: { 
                    requiresAuth: false,
                },
            },
            {
                path: '/site/order-payment-cielo',
                name: 'Pagamento pedido Cielo',
                component: () => import('../components/Site/PaymentCieloCurso/index.vue'),
                beforeEnter: (to, from, next) => {
                    // Função de validação
                    const isValid = validateQueryParams(to.query)
                  
                    if (isValid) {
                      next()
                    } else {
                      next('/login')
                    }
                },
                meta: { 
                    requiresAuth: false,
                },
            },
        ]
    },
    {
        path: '/site/calculadora-de-preco-justo-de-acao',
        name: 'Calculadora de Preço Justo de Ação',
        component: () => import('../views/Site/CalcSiteLayout.vue'),
        meta: { 
            requiresAuth: false,
        },
    },
    {
        path: '/site/download-certificado-curso/:rid',
        name: 'Download Certificado Curso',
        component: () => import('../views/Site/CertificadoLayout.vue'),
        meta: { 
            requiresAuth: false,
        },
    },
    {
        path: '/gerencial',
        name: 'gerencial',
        component: () => import('../views/Gerencial/GerencialLayout.vue'),
        meta: { 
            requiresAuth: true,
        },
        redirect: 'gerencial/dashboard',
        children : [
            {
                path: '/gerencial/cadastros/clientes/investcoach',
                name: 'Relatorio de investCoach',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelInvestCoach/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/account',
                name: 'Perfil usuário',
                component: () => import('../components/Gerencial/PerfilUser/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/configuracoes',
                name: 'Configuracoes',
                component: () => import('../components/Gerencial/Configuracoes/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/dashboard',
                name: 'gestao-dashboard',
                component: () => import('../views/Gerencial/DashboardLayout.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/produtos',
                name: 'Produtos',
                component: () => import('../components/Gerencial/Cadastros/Produtos/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/usuarios',
                name: 'Funcionários/Usuários',
                component: () => import('../components/Gerencial/Cadastros/Usuarios/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/vendedores',
                name: 'Vendedores',
                component: () => import('../components/Gerencial/Cadastros/Vendedores/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/vendedores/relatorio',
                name: 'Vendedores Relatorio',
                component: () => import('../components/Gerencial/Cadastros/Vendedores/RelatorioVendedor/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/clientes',
                name: 'Clientes',
                component: () => import('../components/Gerencial/Cadastros/Clientes/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/clientes/contratos',
                name: 'Contratos Clientes',
                component: () => import('../components/Gerencial/Cadastros/Clientes/ContratosCliente/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/clientes/:rid',
                name: 'Cliente - Detalhes',
                component: () => import('../components/Gerencial/Cadastros/Clientes/Detalhe/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/pedidos',
                name: 'Pedidos',
                component: () => import('../components/Gerencial/Pedidos/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/pedidos/:rid',
                name: 'Pedidos - Detalhe',
                component: () => import('../components/Gerencial/Pedidos/PedidoDetalhe/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/contas-receber',
                name: 'Contas à Receber',
                component: () => import('../components/Gerencial/ContasReceber/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/contas-receber/:rid',
                name: 'Contas à Receber - Detalhe',
                component: () => import('../components/Gerencial/ContasReceber/Detalhe/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cobrancas',
                name: 'Cobranças',
                component: () => import('../components/Gerencial/Cobrancas/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cobrancas/:rid',
                name: 'Cobranças - Detalhes',
                component: () => import('../components/Gerencial/Cobrancas/DetalheCobranca/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/master',
                name: 'Operações Master',
                component: () => import('../components/Gerencial/Operacoes/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/gerar-ordem',
                name: 'Gerar ordem',
                component: () => import('../components/Gerencial/Operacoes/OperacaoOrdem/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/rascunho-ordens',
                name: 'Rascunho ordens',
                component: () => import('../components/Gerencial/Operacoes/RascunhoOrdem/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/rascunho-ordens-email/',
                name: 'E-mail Financeiro',
                component: () => import('../components/Gerencial/Operacoes/EmailFinanOrdem/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/enviar-ordens-email/:rascunho_rid',
                name: 'Enviar E-mail Financeiro',
                component: () => import('../components/Gerencial/Operacoes/DetailEmailOrdem/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/cliente',
                name: 'Operações Cliente',
                component: () => import('../components/Gerencial/Operacoes/OperacoesCliente/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/clientes/relatorio-performace/:rid',
                name: 'Relatorio de Performace',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelatorioPerformance/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/ordens-mensal/',
                name: 'Relatorio de Ordens mensal',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelOrdensMensal/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/controle-lft-logs/',
                name: 'Controle LFT - Logs',
                component: () => import('../components/Gerencial/Cadastros/Clientes/ControleLFTLogs/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/renovacao-lft/',
                name: 'Renovação LFT',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RenovacaoLFT/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/clientes/relatorio-desempenho/:rid',
                name: 'Relatorio de Desempenho',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelatorioDesempenho/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/clientes/relatorio-dados/:rid',
                name: 'Relatorio de Dados',
                component: () => import('../components/Gerencial/Cadastros/Clientes/RelatorioDados/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/acompanhar',
                name: 'Acompanhar Ordens',
                component: () => import('../components/Gerencial/Operacoes/AcompanharOrdens/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/acompanhar/:rid',
                name: 'Acompanhar Ordens - Detalhes',
                component: () => import('../components/Gerencial/Operacoes/AcompanharOrdens/DetalheOrdem/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/ativos-referencia',
                name: 'Ativos de Refência',
                component: () => import('../components/Gerencial/Operacoes/AtivosReferencia/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/operacoes/simulacao-rolagem',
                name: 'Simulação Rolagem',
                component: () => import('../components/Gerencial/Operacoes/SimulacaoRolagem/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/cadastros/taxa-selic',
                name: 'Taxa Selic',
                component: () => import('../components/Gerencial/Cadastros/TaxaSelic/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/banco-conciliacao',
                name: 'Banco e conciliação',
                component: () => import('../views/Gerencial/ConciliacaoLayout.vue'),
                meta: { 
                    requiresAuth: true,
                },
                redirect: 'gerencial/categorias',
                children : [
                    {
                        path: '/gerencial/banco-conciliacao/categorias',
                        name: 'Categorias',
                        component: () => import('../components/Gerencial/BancoConciliacao/Categorias/index.vue'),
                        meta: { 
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/gerencial/banco-conciliacao/contas',
                        name: 'Contas',
                        component: () => import('../components/Gerencial/BancoConciliacao/Contas/index.vue'),
                        meta: { 
                            requiresAuth: true,
                        },
                    },
                    {
                        path: '/gerencial/banco-conciliacao/detalhe-conta/:rid',
                        name: 'Detalhe Contas',
                        component: () => import('../components/Gerencial/BancoConciliacao/DetalheConta/index.vue'),
                        meta: { 
                            requiresAuth: true,
                        },
                        redirect: '/gerencial/banco-conciliacao/detalhe-conta/:rid/receitas',
                        children:[
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/despesas',
                                name: 'Despesas',
                                component: () => import('../components/Gerencial/BancoConciliacao/ContaPagar/index.vue'),
                                meta: { 
                                    requiresAuth: true,
                                },
                            },
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/receitas',
                                name: 'Receitas',
                                component: () => import('../components/Gerencial/BancoConciliacao/ContaReceber/index.vue'),
                                meta: { 
                                    requiresAuth: true,
                                },
                            },
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/conciliacao',
                                name: 'Conciliação',
                                component: () => import('../components/Gerencial/BancoConciliacao/Conciliacao/index.vue'),
                                meta: { 
                                    requiresAuth: true,
                                },
                            },
                            {
                                path: '/gerencial/banco-conciliacao/detalhe-conta/:rid/extrato-financeiro',
                                name: 'ExtratoConta',
                                component: () => import('../components/Gerencial/BancoConciliacao/ExtratoConta/index.vue'),
                                meta: { 
                                    requiresAuth: true,
                                },
                            },
                        ]
                    },
                    {
                        path: '/gerencial/banco-conciliacao/conta-pagar/:conta_rid',
                        name: 'Contas a pagar',
                        component: () => import('../components/Gerencial/BancoConciliacao/ContaPagar/index.vue'),
                        beforeEnter : (to, from, next) => {
                            let regex  = /^[A-Za-z][0-9]{12}$/

                            console.log(to.params.conta_rid)
                            console.log(regex.test(to.params.conta_rid))
                            // if(to.params && to.params.conta_rid.match(teste)) 
                            //     return next()
                            // else
                            //     return next('/gerencia')
                            return next()
                        },
                        meta: { 
                            requiresAuth: true,
                        },
                    },
                ]
            },       
            {
                path: '/gerencial/nota-fiscal',
                name: 'Nota Fiscal',
                component: () => import('../components/Gerencial/NotaFiscal/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/nota-fiscal/:rid',
                name: 'Nota Fiscal Detalhe',
                component: () => import('../components/Gerencial/NotaFiscal/NotaDetail/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            {
                path: '/gerencial/agenda-cursos',
                name: 'Agenda de Cursos',
                component: () => import('../components/Gerencial/AgendaCursos/index.vue'),
                meta: { 
                    requiresAuth: true,
                },
            },
            // {
            //     path: '/gerencial/config-mensagens',
            //     name: 'Conf. de mensagens',
            //     component: () => import('../components/Gerencial/ConfigMensagens/index.vue'),
            //     meta: { 
            //         requiresAuth: true,
            //     },
            // },
        ]
    },
    {
        path: '*',
        redirect: '/login'
    }
]