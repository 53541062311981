import DefaultUtils from '../../utils/default.utils'
import ApiService from '../../services/api.service'
import VueCookies from "vue-cookies"
import jwtDecode from 'jwt-decode'

const cookieUserName = 'user_session'
const cookieTokenName = 'access_token'

const _user = DefaultUtils.jsonPaseStr(DefaultUtils.base64(DefaultUtils.getCookie(cookieUserName)))
const _token = DefaultUtils.base64(DefaultUtils.getCookie(cookieTokenName))

//console.log("_user",_user)

const state = {
    user: typeof _user === 'boolean' ? {} : _user,
    token: _token || "",
    status: null,
    tokensExpiry: null,
}

// getters
const getters = {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    tokensExpiry: state => state.tokensExpiry,
    userOnline: state => state.user,
    userTipo: state => state.user.Role,
}

// actions
const actions = {

    async login({ commit }, model) {
        const response = await ApiService.chamaApiV2("v2/api/access/login", model)

        if (response.Sucesso) {
            commit("auth_success", response)
            return state.user
        } else {
            commit("auth_error")
        }
        return response
    },
    logout({ commit }) {
        commit("logout")
    },
}

// mutations
const mutations = {

    auth_request(state) {
        state.status = "loading"
    },
    auth_success(state, resp) {
        const _user = jwtDecode(resp.Token)

        state.status = "success"
        state.token = resp.Token
        state.tokensExpiry = _user.expires

        state.user = {
            Name: _user.unique_name,
            Email: _user.Email,
            RID: _user.RID,
            Role: _user.role
        }
        const tempoDeExpiracao = new Date(_user.expires).toUTCString() // 4 horas 
        VueCookies.set(cookieTokenName, resp.Token, tempoDeExpiracao)
        DefaultUtils.setUserData(state.user)
    },
    auth_error(state) {
        state.status = "error"
        state.token = null
        state.user = {}
        state.tokensExpiry = null

        VueCookies.remove(cookieTokenName)
        VueCookies.remove(cookieUserName)
    },
    logout(state) {
        state.status = null
        state.token = null
        state.user = {}
        state.tokensExpiry = null

        VueCookies.remove(cookieTokenName)
        VueCookies.remove(cookieUserName)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}